:root {
  --color-background: #f1f0ee;
  --color-light: #e9e9e9;
  --color-light2: #dedddd;
  --color-light3: #bababa;
  --color-table-header: #6b309f;
  --color-primary: #db0000;
  --color-primary-hover: #b0bd19;
  --color-secondary: #6b309f;
  --color-secondary-hover: #cd99fe;
  --color-tertiary: #cd99fe;
  --color-tertiary-hover: #a47acb;
  --color-gray: #333452;
  --color-gray-hover: #111638;
  --color-success: #5f8906;
  --color-success-light: #bcfa37;
  --color-danger: #ff6666;
  --color-warning: #fec714;
  --color-info-icon: #0180ff;
  --color-info: #61c8d8;
  --color-info-table: white;
  --color-text: #221f1f;
  --color-text-light: #4d5253;
  --color-dark: #221f1f;
  --color-dark2: #1f1c1c;
  --color-dark3: #1b1919;
}
