@use "@angular/material" as mat;
@import "./../../../node_modules/@nucleasa-uxui/ds-portais-externos/styles/style.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@300");
@import "theme/colors.scss";
@import "theme/button.scss";
@import "theme/table.scss";

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: var(--color-background);
}

.modal {
  min-width: 100%;
}

#primeiro-checkbox .mdc-checkbox__background {
  border-color: #ffffff !important;
}

.mat-mdc-paginator-container {
  background-color: #fafafa !important;
}

/* angular material theme */
$custom-primary: mat.define-palette(mat.$grey-palette);
$custom-accent: mat.define-palette(mat.$cyan-palette);
$custom-warn: mat.define-palette(mat.$red-palette);

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
      warn: $custom-warn,
    ),
  )
);

@include mat.all-component-themes($custom-theme);

.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important; /* Ajuste a opacidade conforme necessário */
}

mat-dialog-content {
  padding: 0 !important;
  box-sizing: border-box;

  .body {
    padding: 15px;
  }

  .close-button {
    color: white;
    position: absolute;
    top: -3px;
    right: 0px;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.2rem;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .message {
    color: var(--color-text-light);
    font-size: 1rem;
    text-align: justify;
  }

  .title {
    display: flex;
    align-items: center;
    color: white;
    padding: 7px;
    background-color: rgba(0, 105, 92, 1);
    font-weight: 400;
    font-size: 1rem;
    border-bottom: 1px solid black;

    mat-icon {
      margin-right: 5px;
    }
  }
}

.link {
  color: var(--color-info);
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.clickable {
  cursor: pointer;
}
